/**
 * This is the main axios handler, where is configured to handle
 * all the incoming and upconfimg requests
 */
import { getObject } from '@/helpers/storage'
import { URL } from '@/constants'

const untokenedURLs = ['/api/auth/login']

const configureNotification = (translate, notification, message = null, status = null) => {
  if (translate) {
    notification.warning({
      title: translate.title ?? translate ?? 'Un mensaje',
      message: translate.message,
      showClose: true,
      isCollapsed: false,
      duration: 10000
    })
  } else {
    notification.warning({
      title: 'Ha ocurrido un error inesperado',
      message: translate?.message ?? `#development${status ? `: ${status}` : ''} ${message}`,
      showClose: true,
      duration: 10000
    })
  }
}

const parseMessage = (string) => {
  let message = string.split(' ').filter($0 => $0.trim() !== '' && $0.trim() !== ' ')
  return message?.[0] ?? string ?? 'untranslated'
}

/**
 * implemented at ~/main.js
 */
export default ({ axios, store, notification, lang }) => {

  axios.defaults.baseURL = URL

  /**
   * the config must always be returned
   * @description this function is called after the request is sent
   * @return {JSON} config
   * @throws {Response} error
   */
  axios.interceptors.response.use(response => {
    if (response.headers.authorization) {
      store.dispatch('session/setNewToken', response.headers.authorization.split(' ')[1])
    }
    return response
  }, error => {
    // const lang = store.getters['lang']
    /**
     * to start the axios request validation we must start validating
     * that the response is readable, if its readable we can proceed
     * to read the status codes.
     */
    if (error.hasOwnProperty('response')) {

      const message = parseMessage(error?.response?.data?.message)
      const status = error?.response?.status

      const translate = lang.messages.errors[message];

      switch (status) {
        case 500: console.error('Consultar api para mas detalles de este error');
          break;
        case 400:
          if(error.response.data.error_code === 'SEC001'){
            notification.error({
              title: 'Imposible procesar',
              message: `${error.response.data.description}`,
              showClose: true,
              duration: 5000
            })
          
          }else if(error.response.data.message === 'prelation_already_exists'){
            notification.error({
              title: 'Imposible procesar',
              message: `La prelación ya existe`,
              showClose: true,
              duration: 5000
            })
          }else if(error.response.data.message === 'unable_to_find_enabled_preregisters_period'){
            break;
          } else{
            notification.error({
              title: 'Imposible procesar',
              message: `Consultar logs para más información`,
              showClose: true,
              duration: 5000
            })
          }
          break;
        case 401:
          if (!(error.response.data.message === 'Token not provided')) {
            store.dispatch('setErrors', { email: ['invalid_credentials'] })
            configureNotification(translate, notification, message, status)
            store.dispatch('session/vanish')
          } else {
            configureNotification(translate, notification, message, status)
          }
          break;
        case 403:
        case 409:
          configureNotification(translate, notification, message, status)
          break;
        case 404:
          configureNotification(translate, notification, message, status)

          if (message === "the_token_has_been_blacklisted") {
            store.dispatch('session/vanish')
          }

          break
        case 422:
          notification.error({
            title: 'Imposible procesar',
            message: `Por favor verifique la información ingresada`,
            showClose: true,
            duration: -1
          })
          store.dispatch('setErrors', error.response.data.errors)
          break
        default: console.error('unhandled error, status code =>', status)
      }
    } else {

      if (!axios.isCancel(error)) {
        notification.warning({
          message: `No fue posible contactar con el servidor`,
          showClose: true
        })
      }
    }
    return Promise.reject(error);
  });

  /**
   * the config must always be returned
   * @description this function is called before send the request to the server
   * @return {JSON} config
   * @throws {Response} error
   */
  axios.interceptors.request.use(config => {
    const user = getObject('session')
    const token = localStorage.getItem(process.env.VUE_APP_USER_TOKEN_NAME)

    if (user && !untokenedURLs.includes(config.url)) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    if (config.cancellable === undefined || config.cancellable === true) {
      let source = axios.CancelToken.source();

      // Set cancel token on this request
      config.cancelToken = source.token;

      // Add to vuex to make cancellation available from anywhere
      store.commit('ADD_CANCEL_TOKEN', source);
    }

    let encoded = encodeURI(config.url)
    config.url = encoded

    return config
  }, error => Promise.reject(error))
}
