/**
 *
 * @param axios
 * @param mapQueryString
 * @returns {{changeSection: (function(*, *=): *), courses: (function(*, *=): *), absences: (function(*, *=): *), verifySemesterApproval: (function(*): *), binnacles: (function(*, *=): *), show: (function(*, *=): *), index: (function(*=): *), update: (function(*, *=): *), store: (function(*=): *), delete: (function(*): *), specializationHistory: (function(*, *=): *), nextSemester: (function(*, *=): *), updateLesson: (function({lesson: *, student: *}, *=): Promise<*>), schedules: (function(*, *=): *), inscription: (function(*, *=): *), notifications: (function(*, *=): *), lessons: (function(*, *=): *)}}
 */
export default ({ axios, mapQueryString, withoutInvalidValues }) => ({
    /**
     *
     * @param filters
     * @returns {*}
     */
    index: (filters = {}) => axios.get(`/api/students${mapQueryString(filters)}`),
    /**
     *
     * @param id
     * @param filters
     * @returns {*}
     */
    show: (id, filters) => axios.get(`/api/students/${id}${mapQueryString(filters)}`),
    /**
     *
     * @param id
     * @param filters
     * @returns {*}
     */
    binnacles: (id, filters) => axios.get(`/api/students/${id}/binnacles${mapQueryString(filters)}`),
    /**
     *
     * @param id
     * @param filters
     * @returns {*}
     */
    notifications: (id, filters) => axios.get(`/api/students/${id}/notifications${mapQueryString(filters)}`),
    /**
     *
     * @param body
     * @returns {*}
     */
    store: (body) => axios.post(`/api/students`, withoutInvalidValues(body)),
    /**
     *
     * @param id
     * @param body
     * @returns {*}
     */
    update: (id, body) => axios.patch(`/api/students/${id}`, withoutInvalidValues(body)),
    /**
     *
     * @param id
     * @returns {*}
     */
    delete: (id) => axios.delete(`/api/students/${id}`),
    /**
     *
     * @param id
     * @param body
     * @returns {*}
     */
    inscription: (id, body) => axios.post(`/api/students/${id}/re-inscription?throw_on_any_error=0`, withoutInvalidValues(body)),
    /**
     *
     * @param id
     * @param filters
     * @returns {*}
     */
    schedules: (id, filters = {}) => axios.get(`/api/students/${id}/schedules${mapQueryString(filters)}`),
    /**
     *
     * @param id
     * @param section
     * @returns {*}
     */
    changeSection: (id, section) => axios.post(`/api/students/${id}/change-section`, { new_section_id: section }),
    /**
     *
     * @param id
     * @param filters
     * @returns {*}
     */
    specializationHistory: (id, filters = {}) => axios.get(`/api/students/${id}/specialization-history${mapQueryString(filters)}`),
    /**
     *
     * @param id
     * @param filters
     * @returns {*}
     */
    courses: (id, filters = {}) => axios.get(`/api/students/${id}/courses${mapQueryString(filters)}`),
    /**
     *
     * @param id
     * @param filters
     * @returns {*}
     */
    absences: (id, filters = {}) => axios.get(`/api/students/${id}/absences${mapQueryString(filters)}`),
    /**
     *
     * @param id
     * @param filters
     * @returns {*}
     */
    lessons: (id, filters = {}) => axios.get(`/api/students/${id}/lessons${mapQueryString(filters)}`),
    /**
     *
     * @param lesson
     * @param student
     * @param body
     * @returns {Promise<*>}
     */
    updateLesson: ({ lesson, student }, body) => axios.patch(`/api/lessons/${lesson}/students/${student}`, body),
    /**
     *
     * @param id student
     * @param filters
     * @returns {*}
     */
    nextSemester: (id, filters = {}) => axios.get(`/api/students/${id}/courses/next-semester${mapQueryString(filters)}`),
    /**
     *
     * @param id student
     * @returns {*}
     */
    verifySemesterApproval: (id) => axios.post(`/api/students/${id}/approves-current-semester`),
    /**
     * // ?student_id=979&course_id=5&section_id=120&work_title=Prueba
     * @param query
     * @reeturns {*}
     */
    downloadWorkCover: (query = {}) => axios.get(`/api/download-work-cover${mapQueryString(query)}`),
    withdrawCourse: (student_id, body) => axios.post(`/api/students/${student_id}/withdraw-courses`, body),
    periodAverageNote: (student_id, query = {}) => axios.get(`/api/students/${student_id}/period-average-note${mapQueryString(query)}`),
    retireStudent: (student_id, body = {}) => axios.patch(`/api/students/${student_id}/retire`, body),
    listWithdraws: (query = {}) => axios.get(`/api/students/${mapQueryString(query)}`),
    // withdrawStudent: (id, body = {}) => axios.post(`/api/students/${id}/withdrawn`, body)
})
